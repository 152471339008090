<script setup lang="ts">
const showBookingModal = ref(false)
const accentColor: { value: string } = inject('accentColor') as { value: string }
</script>

<template>
  <div>
    <div @click="showBookingModal = !showBookingModal">
      <slot />
    </div>
    <Teleport v-if="showBookingModal" to="#portal-destination">
      <div

        class="fixed inset-0 px-8 z-[9999] flex justify-center items-start py-12"
      >
        <div class="absolute inset-0 bg-black opacity-80 pointer-cursor" :style="{ backgroundColor: accentColor.value }" @click="showBookingModal = false" />
        <button class="absolute top-0 right-0 text-xs uppercase m-4 text-gray-200" @click="showBookingModal = !showBookingModal">
          Close
        </button>
        <FlightBookingForm />
      </div>
    </Teleport>
  </div>
</template>

<style scoped>

</style>
